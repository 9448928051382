import React, { useEffect } from "react";
import { useState } from "react";
import Filter from "./components/Filter";
import CardListMobile from "./components/CardListMobile";
import CardList from "./components/CardList";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import actionsForNavigation from "store/actions/navigation";
import AuthActions from "store/actions/auth";
import Hidden from "@material-ui/core/Hidden";
import { db } from "firebase/client";
import { Typography } from "@material-ui/core";
import Loader from "components/Loader/Loader";

// stylings
import Style from "assets/jss/material-dashboard-pro-react/views/ordersStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import NoOrders from "./components/NoOrders";

const sortArr = [
  "All Categories",
  "Luxury Goods",
  "Travel",
  "Electronics",
  "Experiences",
  "Gift Cards",
  "Raffles",
];

export function OrdersPage(props) {
  const { classes, history, userId, rewards } = props;
  const [searchValue, setSearchValue] = useState("");
  const [sortValue, setSortValue] = useState("All Categories");
  const [arr, setArr] = useState(false);
  const [copyArr, setCopyArr] = useState([]);
  const [orderType, setOrderType] = useState("Pending");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const itemsPerPageMobile = 5;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0);
  const [mobileEndIndex, setMobileEndIndex] = useState(itemsPerPageMobile);
  const [orderData, setOrderData] = useState(false)

  const handleFilter = (value) => {
    const valueLowerCase = value.toLowerCase().replace(/ +/g, "");
    let filteredArr = [];
    if (value === "All Categories") {
      setArr(copyArr);
    } else {
      Object.keys(copyArr).map((key) => {
        console.log("checking ", copyArr[key].orderId);
        if (copyArr[key]["categories"] === undefined) {
          console.log("checking reward", copyArr[key]);
        }
        let val = copyArr[key]["categories"];
        Object.keys(val).map((valKey) => {
          if (valKey.toLowerCase() === valueLowerCase && val[valKey] === true) {
            filteredArr.push(copyArr[key]);
          }
        });
      });
      setArr(filteredArr);
    }
  };

  const getOrders = () => {
    let orders = [];
    let testArr = []
    return db
      .collection("users")
      .doc(userId)
      .collection("orders")
      .get()
      .then((snapshots) => {
        if (!snapshots.empty) {
          snapshots.forEach((doc) => {
            let orderData = doc.data().orderData;
            testArr.push(doc.data())
            for (let i = 0; i < orderData.length; i++) {
              orders.push(orderData[i]);
            }
          });
          setOrderData(testArr)
          setArr(orders);
          setCopyArr(orders);
        } else {
          setArr([]);
          setCopyArr([]);
        }
      });
  };

  const handleOrdersToggle = (value) => {
    setOrderType(value);
  };

  useEffect(() => {
    if (!arr) {
      getOrders();
    }
  }, []);

  if (!arr || !orderData) return <Loader fromLogin={false} />;

  return (
    <div>
      <Hidden mdUp implementation="css">
        <GridContainer>
          <GridItem
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "60px",
            }}
          >
            <Typography variant="h2" className={classes.ordersTitleMobile}>
              Your Orders
            </Typography>
          </GridItem>
        </GridContainer>
        <Card className={classes.cardForFilterList}>
          <GridContainer>
            <GridItem xs={12}>
              <Filter
                sortArr={sortArr}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                sortValue={sortValue}
                setSortValue={setSortValue}
                handleOrdersToggle={handleOrdersToggle}
                orderType={orderType}
                fromDate={fromDate}
                setFromDate={setFromDate}
                toDate={toDate}
                setToDate={setToDate}
                handleFilter={handleFilter}
              />
            </GridItem>

            {arr.length > 0 ? (
              <GridItem xs={12}>
                <CardListMobile
                  arr={arr}
                  itemsPerPageMobile={itemsPerPageMobile}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  index={index}
                  setIndex={setIndex}
                  mobileEndIndex={mobileEndIndex}
                  setMobileEndIndex={setMobileEndIndex}
                  orderType={orderType}
                />
              </GridItem>
            ) : (
              <>
                <GridItem xs={12}>
                  <NoOrders history={history} />
                </GridItem>
              </>
            )}
          </GridContainer>
        </Card>
      </Hidden>

      <Hidden smDown implementation="css">
        <GridContainer>
          <GridItem
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Typography variant="h2" className={classes.ordersTitle}>
              Your Orders
            </Typography>
          </GridItem>
        </GridContainer>

        <Card className={classes.cardForFilterList}>
          <GridContainer>
            <GridItem xs={12}>
              <Filter
                sortArr={sortArr}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                sortValue={sortValue}
                setSortValue={setSortValue}
                handleOrdersToggle={handleOrdersToggle}
                orderType={orderType}
                fromDate={fromDate}
                setFromDate={setFromDate}
                toDate={toDate}
                setToDate={setToDate}
                handleFilter={handleFilter}
              />
            </GridItem>

            {arr.length > 0 ? (
              <GridItem xs={12}>
                <CardList arr={arr} orderType={orderType} orderData={orderData}/>
              </GridItem>
            ) : (
              <>
                <GridItem xs={12}>
                  <NoOrders history={history} />
                </GridItem>
              </>
            )}
          </GridContainer>
        </Card>
      </Hidden>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userId: state.auth.user.uid,
    rewards: state.rewards.rewards,
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({
    edit: (key) =>
      dispatch(
        actionsForNavigation.openChild("Profile Editor", "/admin/user/editor")
      ),
    logout: () => dispatch(AuthActions.startLogout()),
  });
}

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
  )(OrdersPage)
);

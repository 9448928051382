import React, { useEffect } from "react";
import { useState } from "react";
import compose from "recompose/compose";
import { batch, connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Filter from "./components/Filter";
import Table from "./components/Table";
import { db } from "firebase/client";
import { reportSelections } from "../reportSelections";
// mui
import { Card, Hidden, Typography } from "@material-ui/core";

// components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// styling
import Style from "assets/jss/material-dashboard-pro-react/views/reportsStyle";
import withStyles from "@material-ui/core/styles/withStyles";

import { zip } from "lodash";
import { ptsFormula } from "globalCalculationFunction/globalCalculationFunction";
import Loader from "components/Loader/Loader";

const headers = [
  { label: "Merchant #", key: "merchantNumber" },
  { label: "Merchant Name", key: "name" },
  { label: "Quantity", key: "quantity" },
  { label: "Points Earned", key: "pointsEarned" },
  { label: "Day Total", key: "dayTotal" },
  { label: "", key: "action" },
];

const csvHeaders = [
  { label: "Merchant #", key: "merchantNumber" },
  { label: "Merchant Name", key: "name" },
  { label: "Quantity", key: "quantity" },
  { label: "Points Earned", key: "pointsEarned" },
  { label: "Day Total", key: "dayTotal" },
  { label: "Date", key: "reportDate" },
  { label: "Basis Points", key: "userBasisPts" },
];

// const csvHeaders = [
//     { label: "merchantName", key: "name" },
//     { label: "merchantNumber", key: "merchantNumber" },
//     { label: "email", key: "email" },
//     { label: "reportDate", key: "reportDate" },
//     { label: "transactionCount", key: "transactionCount" },
//     { label: "avgTrans", key: "avgTrans" },
//     { label: "batchNumber", key: "batchNumber" },
//     { label: "netAmount", key: "netAmount" },
//     { label: "prepaidDiscount", key: "prepaidDiscount" },
//     { label: "returnAmount", key: "returnAmount" },
//     { label: "saleAmount", key: "saleAmount" },
//     { label: "terminalNumber", key: "terminalNumber" },
// ];

export function SalesByMerchant(props) {
  const { classes, history, userType, user, userCompany } = props;
  const [reportArr, setReportArr] = useState([]);
  const [sortValue, setSortValue] = useState("");
  const [reportType, setReportType] = useState("Sales by Merchant");
  const [fromDate, setFromDate] = useState(moment().format("MMMM DD YYYY"));
  const [toDate, setToDate] = useState(moment().format("MMMM DD YYYY"));
  const itemsPerPage = 9;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(itemsPerPage);
  const [data, setData] = useState(false);
  const [batchInfoArr, setBatchInfoArr] = useState(false);
  const [copyArr, setCopyArr] = useState([]);
  const [points, setPoints] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [zippedArr, setZippedArr] = useState(false);

  const numberFormatterDecimals = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const numberFormatter = new Intl.NumberFormat("en-US", {});

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage);
    setEndIndex(selected * itemsPerPage + itemsPerPage);
  };

  const handleReportToggle = (value) => {
    setReportType(value);
  };

  const handleSearch = () => {
    if (searchString) {
      const filtered = copyArr.filter((f) => {
        if (f.merchantName.toLowerCase().includes(searchString.toLowerCase())) {
          return true;
        }
        if (
          f.merchantNumber.toLowerCase().includes(searchString.toLowerCase())
        ) {
          return true;
        }
      });
      setBatchInfoArr(filtered);
    } else {
      setBatchInfoArr(copyArr);
    }
  };

  useEffect(() => {
    reportSelections.map((data) => {
      if (data.type === "Admin" && userType.includes("Admin")) {
        setReportArr((prevArr) => [...prevArr, data]);
      }
    });
  }, []);

  const getData = () => {
    let arr = [];
    let pendArr = [];
    let combineArr = [];
    let docArr = [];

    return db
      .collection("users")
      .where("IsoAdminCompanyName", "==", userCompany)
      .get()
      .then(async (snapshots) => {
        if (!snapshots.empty) {
          snapshots.forEach((s) => {
            s = s.data();
            combineArr.push(s);
          });
          // setData(arr)
        } else {
          setData([]);
        }
        // db
        //     .collection('iso-temp-user')
        //     .where("IsoAdminCompanyName", "==", userCompany)
        //     .get()
        //     .then((snapshots) => {
        //         if (!snapshots.empty) {
        //             snapshots.forEach((s) => {
        //                 s = s.data()
        //                 pendArr.push(s)
        //             })
        //             combineArr = [...arr, ...pendArr]
        //             setData(combineArr)
        //         }
        //         else {
        //             combineArr = [...arr, ...pendArr]
        //             setData(combineArr)
        //         }
        if (combineArr.length > 0) {
          for (let i = 0; i < combineArr.length; i++) {
            if (combineArr[i].merchantId) {
              db.collection("merchants-live")
                .where("merchantNumber", "==", combineArr[i].merchantId)
                .get()
                .then(async(snapshots) => {
                  if (!snapshots.empty) {
                    snapshots.docs.forEach(async (doc) => {
                      await db.collection("merchants-live")
                        .doc(doc.data().docId)
                        .collection("batch-summary")
                        .get()
                        .then((subSnapshot) => {
                          if (!subSnapshot.empty) {
                            subSnapshot.docs.forEach((subDoc) => {
                              subDoc = subDoc.data();
                              let merchantName = combineArr[i].companyName;
                              let email = combineArr[i].email;
                              let userBasisPts = combineArr[i].userBasisPts;
                              let newObj = {
                                ...subDoc,
                                merchantName,
                                email,
                                parentDocId: doc.data().docId,
                                userBasisPts,
                              };
                              docArr.push(newObj);
                            });
                            setCopyArr(docArr);
                            setBatchInfoArr(docArr);
                          } else {
                            setCopyArr([]);
                            setBatchInfoArr([]);
                          }
                        });
                    });
                  } else {
                    setBatchInfoArr([]);
                  }
                });
            }
          }
          setData(true)
        } else {
          setBatchInfoArr([]);
        }

        // })
      });
  };

  useEffect(() => {
    if (!data || !batchInfoArr) {
      getData();
    }
  }, []);

  useEffect(() => {
    if (batchInfoArr) {
      var arr = [];
      setPoints([]);
      batchInfoArr.map((d) => {
        const floorDollarPerBassisPts = ptsFormula(d.netAmount, d.userBasisPts);
        arr.push(floorDollarPerBassisPts);
        setPoints((prevArr) => [...prevArr, floorDollarPerBassisPts]);
      });
      setZippedArr(zip(batchInfoArr, arr));
    } else {
      setZippedArr([]);
    }
  }, [batchInfoArr]);

  if (!batchInfoArr || !data) return <Loader />;

  return (
    <div>
      <Hidden mdUp implementation="css">
        <div>
          <Typography
            variant="h2"
            className={classes.title}
            style={{ color: "#1A405F" }}
          >
            Please View Page on Desktop / Laptop
          </Typography>
        </div>
      </Hidden>

      <Hidden smDown implementation="css">
        <GridContainer>
          <GridItem xs={12}>
            <Typography variant="h2" className={classes.title}>
              Reports
            </Typography>
          </GridItem>

          <GridItem xs={12} style={{ marginTop: "20px" }}>
            <Card style={{ padding: "25px 20px 5px" }}>
              <GridContainer>
                <GridItem xs={12}>
                  {zippedArr.length > 0 ? (
                    <Filter
                      reportSelections={reportArr}
                      sortValue={sortValue}
                      setSortValue={setSortValue}
                      handleReportToggle={handleReportToggle}
                      reportType={reportType}
                      setReportType={setReportType}
                      toDate={toDate}
                      setToDate={setToDate}
                      fromDate={fromDate}
                      setFromDate={setFromDate}
                      csvData={zippedArr}
                      headers={csvHeaders}
                      numberFormatterDecimals={numberFormatterDecimals}
                      numberFormatter={numberFormatter}
                      searchString={searchString}
                      setSearchString={setSearchString}
                      handleSearch={handleSearch}
                    />
                  ) : null}
                </GridItem>

                <GridItem xs={12}>
                  <Table
                    history={history}
                    data={zippedArr}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    index={index}
                    setIndex={setIndex}
                    endIndex={endIndex}
                    setEndIndex={setEndIndex}
                    itemsPerPage={itemsPerPage}
                    handlePageClick={handlePageClick}
                    headers={headers}
                    points={points}
                    setPoints={setPoints}
                    numberFormatterDecimals={numberFormatterDecimals}
                    numberFormatter={numberFormatter}
                  />
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
        </GridContainer>
      </Hidden>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userId: state.auth.user.uid,
    userType: state.auth.user.userType,
    user: state.auth.user,
    userCompany: state.auth.user.companyName,
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({});
}

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
  )(SalesByMerchant)
);

import React from 'react';
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Style from 'assets/jss/material-dashboard-pro-react/views/cartStyle';
import { Card, Typography } from '@material-ui/core'

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

// icons
import { Close, Info } from "@material-ui/icons";
import { MdKeyboardArrowLeft } from 'react-icons/md';
;

export function ConfirmModal(props){
    const{
        classes,
        history,
        user,
        userCompany,
        userMerchant,
        cart,
        numberFormatter,
        originalAmount,
        setConfirmModal,
        orderNumber
    } = props

    let company = userCompany?.replace(/ /g, '').toLowerCase()
    let merchantName = userMerchant?.replace(/ /g, '').toLowerCase()

    return(
        <Card className={classes.confirmModalCard}>
            <GridContainer>
                <GridItem xs={12}>
                <h5 className={classes.cardHeader}>
                    Order #{orderNumber} has been confirmed{" "}
                </h5>
                </GridItem>

                <GridItem xs={12}>
                <h5 className={classes.cardHeader}>
                    Order Status: Pending
                </h5>
                </GridItem>

                <GridItem xs={12}>
                    {cart.map((data, index) => (
                        data.checked === true ?
                            <div className={classes.borderDiv} key={index}>
                                <GridContainer>
                                    <GridItem xs={8}>
                                        <Typography className={classes.itemSummary}>
                                            {data.rewardName}
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Typography className={classes.itemPriceSummary}>
                                            {numberFormatter.format(data.salePrice * 100)}
                                        </Typography>
                                    </GridItem>
                                </GridContainer>
                            </div>
                            : null
                    ))}
                </GridItem>

                <GridItem xs={12} style={{marginTop:"20px"}}>
                {/* <div className={classes.borderDiv}> */}
                    <GridContainer>
                        <GridItem xs={8}>
                            <Typography className={classes.tripTotal}>
                                Total
                            </Typography>

                        </GridItem>

                        <GridItem xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Typography className={classes.totalCostPts} style={{ whiteSpace: "nowrap" }}>
                                {originalAmount ? numberFormatter.format(originalAmount * 100) : "0"} points
                            </Typography>
                        </GridItem>
                    </GridContainer>
                {/* </div> */}

                </GridItem>

                {/* <GridItem
                    xs={6}
                    style={{ justifyContent: "center", display: "flex" }}
                >
                <Button
                    className={classes.checkoutButton}
                    onClick={() => {
                        setConfirmModal(false)
                        // if(user.userType === "IsoMerchants"){
                        //     history.push(`/iso-merchant/${company}/${merchantName}/orders`)
                        //     window.location.reload()
                        // }
                        // if(user.userType === "Admin" || user.userType === "Administrator"){
                        //     history.push('/admin/orders')
                        //     window.location.reload()
                        // }
                        
                    }}
                >
                    <Info style={{ marginRight: "10px", width:"24px" }} /> View Order Details
                </Button>
                </GridItem> */}

                <GridItem
                    xs={12}
                    style={{ justifyContent: "center", display: "flex" }}
                >
                <Button
                    className={classes.checkoutButton}
                    style={{background:"#FECD2C"}}
                    onClick={() => {
                        if(user.userType === "IsoMerchants"){
                            history.push(`/iso-merchant/${company}/${merchantName}/rewards`)
                            window.location.reload()
                        }
                        if(user.userType === "Admin" || user.userType === "Administrator"){
                            history.push('/admin/rewards')  
                            window.location.reload()
                        }
                    }}
                >
                    <MdKeyboardArrowLeft style={{ marginRight: "10px" }} /> Return to Rewards
                </Button>
                </GridItem>
            </GridContainer>
        </Card>
    )
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        userMerchant: state.auth.user.companyName,
        userCompany: state.auth.user.IsoAdminCompanyName,
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(ConfirmModal);
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import ReactPaginate from "react-paginate";
import "./pagination.css";
import moment from "moment"
import { db } from "firebase/client";
// core components
import { MenuItem, Select } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";



//icons
import { ReactComponent as Add } from "assets/img/tuzo/icons/AddCircle.svg";
import { ReactComponent as TransferPoints } from "assets/img/tuzo/icons/TransferPointsWhite.svg";
import { ReactComponent as ViewIcon } from "assets/img/tuzo/icons/ViewIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/tuzo/icons/EditIcon.svg";
import { ReactComponent as TrashIcon } from "assets/img/tuzo/icons/TrashIcon.svg";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import { CheckCircle } from "@material-ui/icons";


const headers = [
    "Merchant ID",
    "Points",
    "Pending Points",
    "Name",
    "Company Name",
    "Email",
    "Type",
    "Actions",
];

const analyticsHeaders = [
    "Points",
    "Pending",
    "Name",
    // "Rewards Purchased",
    "Company Name",
    "Email",
    "Type",
    "Status",
    "Joined",
    "Actions"
]


function DashboardTable(props) {
    const {
        classes,
        history,
        data,
        pageNumber,
        setPageNumber,
        index,
        setIndex,
        endIndex,
        setEndIndex,
        itemsPerPage,
        handlePageClick,
        listSwitch,
        setApproveModal,
        setPtsToApprove,
        setMerchantContact,
        setEmail,
        setBatchInfo
    } = props;

    const numberFormatter = new Intl.NumberFormat('en-US', {
    });

    const capitalizeCell = (string) => {
        const words = string.split(" ");
        for (let i = 0; i < words.length; i++) {
            if (words[i].toUpperCase() !== "LLC") {
                if (i !== words.length - 1) {
                    words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase() + " ";
                }
                else {
                    words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase()
                }
            }
        }
        return words
    }

    const getOrders = (arr) => {
        let newArr = []
        let returnArr = []
        arr.map((data, index) => {
            let orderData = data.orderData
            for (let i = 0; i < orderData.length; i++) {
                newArr.push(orderData[i])
            }
        })
        let filtered = newArr.sort(function (a, b) {
            return b.orderDate.seconds - a.orderDate.seconds;
        })

        return filtered[0].rewardName + ", "
            + filtered[1].rewardName + ", "
            + filtered[2].rewardName
    }

    useEffect(() => {
        data.slice(index, endIndex);
    }, [index]);

    const updateToolTipTitle = (status) => {
        let title = ""
        if(status === "Active"){
            title = "Data feed is available at this time and user has signed in."
        }
        else if(status === "Pending"){
            title = "User was invited into the platform but has not joined or signed in yet."
        }
        else if(status === "Cancelled"){
            title = "Contract was cancelled data feed no longer active, historical data preserved."
        }
        else if(status === "Failed"){
            title = "API has failed no data feed at this time."
        }
        else if(status === "Archived"){
            title = "Admin has deleted this account 'soft delete' hides from user list and future reports, inactive data feed."
        }else{
            title = "User has not signed in data feed is active."
        }
        return title
    }

    const renderTableHeader = () => {
        return (
            <tr
                className={classes.tableRow}
                style={{
                    height: "38px",
                    border: "1px solid #8E8E8E",
                }}
            >
                {listSwitch === "List" ?
                    <>
                        {headers.map((key, index) => {
                            return (
                                <th className={classes.tableHeaders} key={key}>
                                    {key}
                                </th>
                            );
                        })}
                    </>
                    :
                    <>
                        {analyticsHeaders.map((key, index) => {
                            return (
                                <th className={classes.tableHeaders} key={key}>
                                    {key}
                                </th>
                            );
                        })}
                    </>
                }

            </tr>
        );
    };

    const renderTableData = () => {
        return data
            .slice(
                pageNumber * itemsPerPage,
                pageNumber * itemsPerPage + itemsPerPage
            )
            .map((d, index) => {
                return (
                    <tr
                        className={index % 2 === 0 ? classes.tableRow : classes.tableRowOdd}
                        key={d.name}
                    >
                        <td className={classes.tableCellList} >{d[0].merchantNumber}</td>
                        <td className={classes.tableCellList} style={{ fontWeight: 700 }}>{d[0].approvedPts ? d[0].approvedPts : 0}</td>
                        <td className={classes.tableCellList} >{numberFormatter.format(d[1])}</td>
                        <td className={classes.tableCellList}>{d[0].contact ? capitalizeCell(d[0].contact) : "N/A"}</td>
                        <td className={classes.tableCellList}>{d[0].merchantName ? capitalizeCell(d[0].merchantName) : "N/A"}</td>
                        <td className={classes.tableCellList}>{d[0].email ? capitalizeCell(d[0].email) : "N/A"}</td>
                        <td className={classes.tableCellList}>{d[0].userType && d[0].userType.includes("Merchant") ? "Merchant" : d[0].userType? d[0].userType : "Merchant"}</td>
                        <td className={classes.tableCellList}>
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "space-evenly",
                                }}
                            >
                                <Tooltip
                                    id="tooltip-top"
                                    title="View"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        style={{ background: "#FECD2C" }}
                                        className={classes.actionButton}
                                        onClick={() => history.push(`/admin/user-profile/${d[0].uid}`)}
                                    >
                                        <ViewIcon />
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Edit"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        className={classes.actionButton}
                                        style={{ background: "#E2A400" }}
                                        onClick={() => history.push(`/admin/user-profile/${d[0].uid}`)}
                                    >
                                        <EditIcon />
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Assign Reward"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        className={classes.actionButton}
                                        style={{ background: "#56CA93" }}
                                        onClick={() => history.push('/admin/assign-reward')}
                                    >
                                        <Add />
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Transfer Points"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        className={classes.actionButton}
                                        style={{ background: "#1A405F" }}
                                    >
                                        <TransferPoints />
                                    </Button>
                                </Tooltip>

                                <Tooltip
                                    id="tooltip-top"
                                    title="Approve Points"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        className={classes.actionButton}
                                        style={{ background: "#06667C" }}
                                        onClick={() => {
                                            setPtsToApprove(numberFormatter.format(d[1]))
                                            setMerchantContact(capitalizeCell(d[0].contact))
                                            setEmail(capitalizeCell(d[0].email))
                                            setBatchInfo(d[0])
                                            setApproveModal(true)

                                        }}
                                    >
                                        <CheckCircle />
                                    </Button>


                                </Tooltip>
                            </div>
                        </td>
                    </tr>
                );
            });
    };


    const renderAnalyticsTableData = () => {
        return data
            .slice(
                pageNumber * itemsPerPage,
                pageNumber * itemsPerPage + itemsPerPage
            )
            .map((d, i) => {
                return (
                    <tr
                        className={i % 2 === 0 ? classes.tableRow : classes.tableRowOdd}
                        key={d.name}
                    >
                        <td className={classes.tableCell} style={{ fontWeight: 700 }}>{d[0].approvedPts ? numberFormatter.format(Math.trunc(d[0].approvedPts)) : 0}</td>
                        <td className={classes.tableCell} style={{ fontWeight: 700 }}>{d[0].tuzoPoints ? numberFormatter.format(Math.trunc(d[0].tuzoPoints)) : 0}</td>
                        <td className={classes.tableCell}>{d[0].firstName + " " + d[0].lastName}</td>
                        <td className={classes.tableCell}>{d[0].companyName ? d[0].companyName : "N/A"}</td>
                        <td className={classes.tableCellList}>{d[0].email ? capitalizeCell(d[0].email) : "N/A"}</td>
                        <td className={classes.tableCell}>
                            {
                                d[0].userType === "IsoAdmin" ? "ISO" :
                                    d[0].userType === "IsoMerchants" || d[0].userType === "Merchants" ? "Merchant" :
                                        d[0].userType === "Admin" || d[0].userType === "Administrator" ? "Admin" :
                                            d[0].userType
                            }

                        </td>
                        <td className={classes.tableCell}>
                            {d[0].status ? 
                            <>
                                 <Tooltip
                                    id="tooltip-top"
                                    title={updateToolTipTitle(d[0].status)}
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <div>{d[0].status}</div>
                                </Tooltip>
                            </>
                            : 
                                "N/A"
                            }
                            </td>
                        <td className={classes.tableCell}>{d[0].timestamp ? moment.unix(d[0].timestamp.seconds).format("MM/DD/YY") : "N/A"}</td>
                        <td className={classes.tableCell}>
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "space-evenly",
                                }}
                            >
                                <Tooltip
                                    id="tooltip-top"
                                    title="View"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        style={{ background: "#FECD2C" }}
                                        className={classes.actionButton}
                                        onClick={() => {
                                            if (d[0].uid && d[0].status) {
                                                history.push(`/admin/user-profile/${d[0].uid}`)
                                            } else {
                                                if(d[0].uid){
                                                    history.push(`/admin/temp-user-profile/${d[0].uid}`)
                                                }else{
                                                    history.push(`/admin/temp-user-profile/${d[0].id}`)
                                                }
                                                
                                            }

                                        }}
                                    >
                                        <ViewIcon />
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Edit"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        className={classes.actionButton}
                                        style={{ background: "#E2A400" }}
                                        onClick={() => {
                                            if (d[0].uid) {
                                                history.push(`/admin/user-profile/${d[0].uid}`,
                                                    {
                                                        editState: true
                                                    }
                                                )
                                            } else {
                                                history.push(`/admin/temp-user-profile/${d[0].id}`,{
                                                    editState: true
                                                })
                                            }
                                        }}
                                    >
                                        <EditIcon />
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Assign Reward"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        className={classes.actionButton}
                                        style={{ background: "#56CA93" }}
                                        onClick={() => history.push('/admin/assign-reward')}
                                    >
                                        <Add />
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Transfer Points"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        className={classes.actionButton}
                                        style={{ background: "#1A405F" }}
                                    >
                                        <TransferPoints />
                                    </Button>
                                </Tooltip>
                            </div>
                        </td>
                    </tr>
                );
            });
    };

    return (
        <GridContainer >
            <GridItem xs={12}>
                <table className={classes.rewardsTable} style={{ marginBottom: "10px", marginTop: "10px" }}>
                    <tbody>
                        {renderTableHeader()}
                        {listSwitch === "List" ? renderTableData() : renderAnalyticsTableData()}
                    </tbody>
                </table>
                <GridContainer>
                    <GridItem xs={11} style={{ marginTop: "5px", display: "flex", justifyContent: "flex-end" }}>
                        <h5
                            className={classes.indexes}
                        >
                            {index + 1} - {endIndex} of{" "}
                            {Math.ceil(data.length)}
                        </h5>
                    </GridItem>
                    <GridItem xs={1}>
                        <ReactPaginate
                            forcePage={pageNumber}
                            paginationSize={0}
                            onPageChange={handlePageClick}
                            pageCount={Math.ceil(data.length / itemsPerPage)}
                            nextLabel={
                                <ArrowRight
                                    style={{
                                        fontSize: 25,
                                        fontWeight: "bold",
                                        color: "white",
                                    }}
                                />
                            }
                            previousLabel={
                                <ArrowLeft
                                    style={{
                                        fontSize: 25,
                                        fontWeight: "bold",
                                        color: "white",
                                    }}
                                />
                            }
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        />
                    </GridItem>
                </GridContainer>
            </GridItem>
        </GridContainer>
    );
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.error,
        authenticated: state.auth.authenticated,
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(dashboardStyle)
)(React.memo(DashboardTable));

import React, { useEffect } from 'react';
import moment from "moment"
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { CardContent, IconButton, Typography } from '@material-ui/core';

// @material-ui/icons
import { ReactComponent as Info } from "assets/img/tuzo/icons/InfoGreen.svg";
import { ReactComponent as Diamond } from "assets/img/tuzo/icons/DiamondGreen.svg";
import { ReactComponent as Plane } from "assets/img/tuzo/icons/PlaneGreen.svg";
import { ReactComponent as Computer } from "assets/img/tuzo/icons/ComputerGreen.svg";
import { ReactComponent as Experience } from "assets/img/tuzo/icons/ExperienceGreen.svg";
import { ReactComponent as GiftCard } from "assets/img/tuzo/icons/GiftCardGreen.svg";
import { ReactComponent as NewRaffleIcon } from "assets/img/tuzo/icons/RaffleGreen.svg";
// stylings
import Style from 'assets/jss/material-dashboard-pro-react/views/ordersStyle';
import withStyles from '@material-ui/core/styles/withStyles';



export function CardList(props) {
    const {
        classes,
        arr,
        orderType,
        history,
        user,
        orderData
    } = props;
 
    return (
        <div>
            <GridContainer>
                {orderData.map((d, index) => (
                    <>
                        {orderType.includes(d.orderStatus) ?
                            d.orderData.map((data) => (
                                <GridItem xs={4} md={4} xl={3} style={{ display: "flex" }}>
                                <Card className={classes.rewardsCardListStyling}>
                                    <CardContent style={{ padding: "0px" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <IconButton
                                                onClick={() => {
                                                    if (user.IsoAdminCompanyName) {
                                                        history.push(
                                                            `/iso-merchant/${(user.IsoAdminCompanyName).replace(/ /g, '').toLowerCase()}/${(user.companyName).replace(/ /g, '').toLowerCase()}/orders-detail/${data.orderId}`)
                                                    }
                                                    else {
                                                        history.push(`/merchant/orders-detail/${data.orderId}`)
                                                    }

                                                }}
                                            >
                                                <Info />
                                            </IconButton>
                                            <IconButton className={classes.iconButton}>
                                                {data.categories.luxuryGoods ?
                                                    <Diamond className={classes.icon} />
                                                    :
                                                    data.categories.travel ?
                                                        <Plane className={classes.icon} />
                                                        :
                                                        data.categories.electronics ?
                                                            <Computer className={classes.icon} />
                                                            :
                                                            data.categories.experiences ?
                                                                <Experience className={classes.icon} />
                                                                :
                                                                data.categories.giftCards ?
                                                                    <GiftCard className={classes.icon} />
                                                                    :
                                                                    <NewRaffleIcon className={classes.icon} />
                                                }
                                            </IconButton>
                                        </div>

                                        <div
                                            onClick={() => {
                                                if (user.IsoAdminCompanyName) {
                                                    history.push(
                                                        `/iso-merchant/${(user.IsoAdminCompanyName).replace(/ /g, '').toLowerCase()}/${(user.companyName).replace(/ /g, '').toLowerCase()}/orders-detail/${data.orderId}`)
                                                }
                                                else {
                                                    history.push(`/merchant/orders-detail/${data.orderId}`)
                                                }

                                            }}
                                            className={classes.divHover}
                                        >
                                            <img
                                                src={data.mainImage}
                                                className={classes.cardListImg}
                                            />

                                            <Typography variant="h2" className={classes.cardListTitleNoOverflow}>
                                                {(data.rewardName).length < 33 ? <br /> : null}
                                                {data.rewardName}
                                            </Typography>
                                        </div>

                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <div>
                                                <Typography variant="h2" className={classes.cardListDesc}>
                                                    Order Date
                                                </Typography>
                                                <Typography variant="h2" className={classes.cardListPts}>
                                                    {moment.unix(data.orderDate.seconds).format("MM/DD/YYYY")}
                                                </Typography>
                                            </div>

                                            <div>
                                                <Typography variant="h2" className={classes.cardListDesc}>
                                                    Points Price
                                                </Typography>
                                                <Typography variant="h2" className={classes.cardListPts}>
                                                    {(data.salePricePoints).toLocaleString()}
                                                </Typography>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </GridItem>
                            ))
                        : null}
                    </>
                ))}
            </GridContainer>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        userId: state.auth.user.uid,
        user: state.auth.user
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(CardList));
